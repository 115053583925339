<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-layout justify-end
                  class="mb-8">
            <v-btn color="secondary"
                   v-if="canAddSubContractorsTypes"
                   @click="openForm()"
                   >{{ $t('buttons.add') }}</v-btn>
        </v-layout>
        <v-data-table :headers="headers"
                      :loading="loader"
                      disable-sort
                      :items="rawSubContractorsTypes"
                      disable-pagination
                      hide-default-footer
                      class="elevation-1">
            <template v-for="(headerItem, i) in headers"
                      v-slot:[`header.${headerItem.value}`]="{ header }">
                <SortableHeader :header-item="header"
                                :key="i"
                                @sort="sort($event)"></SortableHeader>
            </template>
            <template v-slot:item.actions="{ item }">
                <HintingIcon class-name="mr-2"
                             :icon="ICONS.EDIT"
                             v-if="canEditSubContractorsTypes"
                             @click="openForm(item.id)"
                             :data-test="item"
                             :tooltip="$t('actions.edit')"></HintingIcon>
                <ButtonWithConfirmation :item-id="item.id"
                                        v-if="canRemoveSubContractorsTypes"
                                        :callback="deleteSubContractorsTypes">
                </ButtonWithConfirmation>
            </template>
        </v-data-table>

        <Pagination :pagination="pagination"
                    data-test="pagination"
                    @change-page="changePage($event)"
                    @change-amount="changeAmount($event)"></Pagination>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { ICONS } from '@/constants/icons';
import SortableHeader from '@/components/widgets/tables/SortableHeader';
import HintingIcon from '@/components/widgets/HintingIcon';
import Pagination from '@/components/widgets/tables/Pagination';
import ButtonWithConfirmation from '@/components/widgets/buttons/ButtonWithConfirmation';

export default {
    name: 'SubContractorsTypesTable',
    components: { SortableHeader, HintingIcon, Pagination, ButtonWithConfirmation },
    props: {
        loader: {
            type: Boolean,
            'default': false
        },
        changedPage: {
            type: [String, Number],
            required: false
        }
    },
    data: () => ({
        ICONS,
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        sortable: {
            name: 'asc'
        }
    }),
    computed: {
        ...mapState('subContractorsTypes', [
            'rawSubContractorsTypes',
            'totalSubContractorsTypesAmount'
        ]),
        headers () {
            return [
                {
                    text: this.$t('incidents.table.headers.seq'),
                    value: 'seq',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('navigation.SubContractorsTypes'),
                    value: 'name',
                    icon: false,
                    sortable: false
                },
                {
                    text: this.$t('incidents.table.headers.actions'),
                    value: 'actions',
                    icon: false,
                    width: '90px',
                    sortable: false
                }
            ];
        },
        canEditSubContractorsTypes () {
            return this.$can(this.$abilityActions.UPDATE, this.$abilitySubjects.SUBCONTRACTORS_TYPES);
        },
        canAddSubContractorsTypes () {
            return this.$can(this.$abilityActions.CREATE, this.$abilitySubjects.SUBCONTRACTORS_TYPES);
        },
        canRemoveSubContractorsTypes () {
            return this.$can(this.$abilityActions.DELETE, this.$abilitySubjects.SUBCONTRACTORS_TYPES);
        }
    },
    watch: {
        totalSubContractorsTypesAmount: {
            handler (val) {
                this.pagination.amountAll = val;
            },
            immediate: true
        },
        changedPage: {
            handler (val) {
                if (val !== this.pagination.page) {
                    this.pagination.page = val;
                }
            }
        }
    },
    methods: {
        changePage (event) {
            this.pagination.page = event;
            this.$emit('change-page', event);
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.$emit('change-amount', event);
        },
        sort (event) {
            switch (this.sortable[event]) {
            case null:
                this.sortable[event] = 'desc';
                break;
            case 'asc':
                this.sortable[event] = 'desc';
                break;
            case 'desc':
                this.sortable[event] = 'asc';
                break;
            }

            this.$emit('sort', {
                name: event,
                value: this.sortable[event]
            });
        },
        openForm (id) {
            if (id || id === 0) {
                this.$store.dispatch('manageSubContractorsTypes/getActiveSubContractorsType', id)
                    .then(() => {
                        this.$store.commit('manageSubContractorsTypes/SET_SUBCONTRACTORS_TYPE_ID', id);
                    });
            }
            this.$store.commit('manageSubContractorsTypes/SET_SUBCONTRACTORS_TYPE_FORM_VISIBILITY', true);
        },
        deleteSubContractorsTypes (id) {
            return this.$store.dispatch('manageSubContractorsTypes/deleteSubContractorsType', id)
                .then(() => {
                    this.$emit('fetch');
                });
        }
    }
};
</script>

<style scoped>

</style>
