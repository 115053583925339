<template>
    <div class="pa-4">
        <h1 class="mb-5 text--xl font-weight-medium">{{ $t('navigation.subcontractors_types') }}</h1>
        <!-- <FiltersBar :withFilters="false"
                    ref="filtersBar"></FiltersBar> -->

        <SubContractorsTypesTable @change-page="changePage($event)"
                        @change-amount="changeAmount($event)"
                        @sort="sortingColumn = $event"
                        @fetch="fetchSubContractorsTypes"
                        :loader="loader"
                        :changed-page="pagination.page"></SubContractorsTypesTable>

        <ManageSubContractorsTypes @fetch="fetchSubContractorsTypes"></ManageSubContractorsTypes>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import SubContractorsTypesTable from '@/components/subContractorsTypes/SubContractorsTypesTable';
// import FiltersBar from '@/components/widgets/tables/FiltersBar';
import ManageSubContractorsTypes from '@/components/subContractorsTypes/ManageSubContractorsTypes';

export default {
    name: 'SubContractorsTypes',
    components: {
        SubContractorsTypesTable,
        // FiltersBar,
        ManageSubContractorsTypes
    },
    data: () => ({
        pagination: {
            page: 1,
            amount: 20,
            amountAll: null
        },
        loader: false,
        sortingColumn: null
    }),
    computed: {
        ...mapFields('search', [
            'search'
        ])
    },
    watch: {
        sortingColumn: {
            handler () {
                this.fetchSubContractorsTypes();
            },
            deep: true
        },
        search: {
            handler (val, oldVal) {
                if ((val && val.length >= 2) || (oldVal && val === '')) {
                    this.pagination.page = 1;
                    this.fetchSubContractorsTypes();
                }
            }
        }
    },
    methods: {
        fetchSubContractorsTypes () {
            this.loader = true;

            const params = {
                'page': this.pagination.page,
                'page_size': this.pagination.amount
            };

            // if (this.sortingColumn) {
            //     params.sort = this.sortingColumn.value === 'asc' ? this.sortingColumn.name : `-${this.sortingColumn.name}`;
            // }

            if (this.search) {
                params.search = this.search;
            }

            this.$store.dispatch('subContractorsTypes/getSubContractorsTypes', params)
                .finally(() => {
                    this.loader = false;
                });
        },
        changePage (event) {
            this.pagination.page = event;
            this.fetchSubContractorsTypes();
        },
        changeAmount (event) {
            this.pagination.page = 1;
            this.pagination.amount = event;
            this.fetchSubContractorsTypes();
        }
    },
    beforeRouteEnter (to, from, next) {
        next(vm => {
            if (vm.$can(vm.$abilityActions.READ, vm.$abilitySubjects.SUBCONTRACTORS_TYPES)) {
                vm.fetchSubContractorsTypes();
            } else {
                vm.$router.push({ name: 'home' });
            }
        });
    },
    beforeRouteLeave (to, from, next) {
        this.search = null;
        next();
    }
};
</script>

<style scoped>

</style>
