<template>
    <v-sheet color="white"
             class="px-0 py-0"
             elevation="0">
        <ValidationObserver ref="obs">
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <TextField :label="$t('labels.name')"
                                   v-model="name"
                                   rules="required"></TextField>
                    </v-col>
                </v-row>

            </v-container>
        </ValidationObserver>
    </v-sheet>
</template>

<script>
import { mapFields } from 'vuex-map-fields';
import { ValidationObserver } from 'vee-validate';
import TextField from '@/components/widgets/forms/TextField';

export default {
    name: 'SubContractorsTypeForm',
    components: { ValidationObserver, TextField },
    props: {
        isEditable: {
            type: Boolean,
            'default': false
        }
    },
    computed: {
        ...mapFields('manageSubContractorsTypes', [
            'subContractorsTypesData.name'
        ]),
        isMobile () {
            return this.$vuetify.breakpoint.xs;
        },
        isFormDirty () {
            return this.$refs.obs.flags.changed;
        }
    },
    methods: {
        resetValidation () {
            return this.$refs.obs.reset();
        },
        validate () {
            return this.$refs.obs.validate();
        }
    }
};
</script>
