<template>
    <Modal :modal-title="title"
           :dialog="subContractorsTypesFormVisibility"
           max-width="600px"
           :persistent="true"
           :closeIcon="false"
           dense
           data-test="modal"
           @keydown.enter="showConfirmation"
           @keydown.esc="closeWithConfirmation"
           @close="close"
           @visibility="visibility($event)">
        <template slot="content">
            <SubContractorsTypesForm ref="form"
                          :is-editable="isEditable">

            </SubContractorsTypesForm>

            <ValidationError v-if="showError"></ValidationError>
        </template>
        <template slot="actions">
            <v-layout align-center
                      justify-end
                      class="mt-3">
                <v-btn color="secondary"
                       outlined
                       class="mr-3"
                       data-test="closeBtn"
                       @click="close">{{ $t('buttons.cancel') }}</v-btn>
                <v-btn color="secondary"
                       data-test="saveBtn"
                       :loading="loader"
                       :disabled="loader"
                       @click="save">{{ $t('buttons.save') }}</v-btn>
            </v-layout>
        </template>
    </Modal>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/templates/Modal';
import SubContractorsTypesForm from '@/components/subContractorsTypes/SubContractorsTypesForm';
import ValidationError from '@/components/widgets/ValidationError';

export default {
    name: 'ManageSubContractorsTypes',
    components: { Modal, SubContractorsTypesForm, ValidationError },
    data: () => ({
        showConfirmationDialog: false,
        loader: false,
        showError: false
    }),
    computed: {
        ...mapState('manageSubContractorsTypes', [
            'subContractorsTypesFormVisibility',
            'subContractorsTypesData',
            'editedSubContractorsTypesId'
        ]),
        isFieldsDirty () {
            return this.$refs.form.isFormDirty;
        },
        title () {
            return this.isEditable ? this.$t('buttons.edit') : this.$t('buttons.add');
        },
        isEditable () {
            return this.editedSubContractorsTypesId !== null && this.editedSubContractorsTypesId !== undefined;
        }
    },
    methods: {
        close () {
            this.$store.commit('manageSubContractorsTypes/SET_SUBCONTRACTORS_TYPE_FORM_VISIBILITY', false);
            this.$refs.form.resetValidation();
            // prevent showing bad data 0.1s
            setTimeout(() => {
                this.$store.commit('manageSubContractorsTypes/SET_SUBCONTRACTORS_TYPE_ID', null);
                this.$store.commit('manageSubContractorsTypes/RESET_SUBCONTRACTORS_TYPE_DATA');
            }, 100);
            this.$emit('fetch');
        },
        visibility (event) {
            this.$store.commit('manageSubContractorsTypes/SET_SUBCONTRACTORS_TYPE_FORM_VISIBILITY', event);
        },
        closeWithConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            } else {
                this.close();
            }
        },
        showConfirmation () {
            if (this.isFieldsDirty) {
                this.showConfirmationDialog = true;
            }
        },
        createSubContractorsTypes () {
            this.loader = true;
            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.loader = false;
                        return this.$store.dispatch('manageSubContractorsTypes/createSubContractorsType')
                            .then(() => {
                                this.close();
                            })
                            .finally(() => {
                                this.$emit('fetch');
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        updateSubContractorsTypes () {
            this.loader = true;
            this.$refs.form.validate()
                .then(valid => {
                    this.showError = !valid;
                    if (valid) {
                        this.loader = false;
                        return this.$store.dispatch('manageSubContractorsTypes/updateSubContractorsType')
                            .then(() => {
                                this.close();
                            })
                            .finally(() => {
                                this.$emit('fetch');
                            });
                    } else {
                        this.loader = false;
                    }
                });
        },
        save () {
            if (this.isEditable) {
                this.updateSubContractorsTypes();
            } else {
                this.createSubContractorsTypes();
            }
        }
    }
};
</script>

<style scoped>

</style>
